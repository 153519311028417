import { Link } from 'gatsby'
import React from 'react'

export default function Button({url, title, target, type = 'primary', inverted = false, className, ...other}) {

  if(!url && !title)
    return null

  const Tag = url?.length > 0 && url !== '#' ? Link : 'button';

  return (
    <Tag to={url} className={`btn btn--${type} ${inverted && `btn--${type}--inverted`} ${className}`} target={target} {...other}>
      {title}
    </Tag>
  )
}
