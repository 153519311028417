import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import React from 'react'

export default function SubNav({items = [] }) {
  return (
    <motion.nav
        className="flex flex-col justify-center pl-10 mt-5 l"
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        exit={{ opacity: '0' }}
        transition={{ type: 'tween', duration: 0.5 }}
      >

        <ul>
          {items.map((item, i) => (
            <li key={i} className="mb-4 last:mb-0">
              <Link to={item.url} className="tracking-tight hover:opacity-50 transition-opacity duration-200 ease-in-out font-normal font-body">{item.label}</Link>
            </li>
          ))}
        </ul>
    </motion.nav>
  )
}
