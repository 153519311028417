import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import IconArrow from '~/components/atoms/IconArrow';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';
import useFormSubmit from '~/hooks/useFormSubmit';
import RadioField from '~/components/atoms/RadioField';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  marketing_contact_type: Yup.string().required(),
});

export default function NewsletterSubscribe({ setIsSubmitted }) {
  const { post, loading, response } = useFormSubmit({
    portalId: '7026924',
    formId: '54fe4638-962d-4508-bde8-3ce6d4f15a3f',
    setIsSubmitted,
  });

  return (
    <FadeInWhenVisible className="w-full">
      <Formik
        initialValues={{
          email: '',
          marketing_contact_type: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await post({
            // ...values,
            email: values.email,
            marketing_contact_type: values.marketing_contact_type,
            gdpr_check_box_text: 1,
          });
        }}
      >
        {(props) => (
          <Form className="relative w-full group">
            <div className="relative group">
              <Field
                name="email"
                type="email"
                className={`bg-transparent border ${
                  props.touched.email && props.errors.email ? 'border-error' : 'border-white'
                } border-opacity-30 w-full group-hover:border-opacity-100 focus:border-opacity-100 outline-none transition-colors duration-200 ease-in-out px-6 py-4 newsletter-input`}
                placeholder="Your Email Address"
              />
              <button
                type="submit"
                className="absolute top-0 bottom-0 flex items-center justify-center w-12 h-full transition-opacity right-4 hover:opacity-50 duration-"
              >
                <IconArrow className="text-white transform -rotate-90" />
              </button>
            </div>
            <div className="flex items-center mt-4 ">
              <div className="mr-4 text-sm">I'm a</div>
              <div className="flex space-x-4" role="group">
                <RadioField
                  name="marketing_contact_type"
                  value="property_owner"
                  id="property-owner"
                  label="Property owner"
                  inputClassName=""
                />
                <RadioField
                  name="marketing_contact_type"
                  value="resident"
                  id="resident"
                  label="Resident"
                  inputClassName=""
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </FadeInWhenVisible>
  );
}
