import useFetch from 'use-http';
import Cookies from "js-cookie";

const submitForm = ({ formId, portalId='7026924', setIsSubmitted, fields = [] }) => {
  const isBrowser = typeof window !== "undefined";
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  // eslint-disable-next-line
  const { post, error, response, loading } = isBrowser ? useFetch(postUrl) : {};

  return {
    error,
    response,
    loading,
    post: async (values) => {
      const hutk = isBrowser ? Cookies.get("hubspotutk") : null;
      const pageUri = isBrowser ? window.location.href : null;
      const pageName = isBrowser ? document.title : null;
      const pageUrl = isBrowser ? window.location.pathname : null;

      const fields = [...Object.keys(values)].map((key) => {
        return {
          name: key,
          value: values[key],
        };
      });

      const data = {
        submittedAt: Date.now(),
        fields,
        context: {
          hutk,
          pageUri,
          pageName,
        },
      };

      await post(data);

      if (response.ok === true && typeof setIsSubmitted !== 'undefined') {
        setIsSubmitted(true);
      }

      if (typeof window?.dataLayer?.push !== 'undefined') {
        window.dataLayer.push({ event: 'form-submit', pageUrl });
      }
    },
  };
}

export default submitForm;
