import { Link } from 'gatsby';
import React, { useState } from 'react';
import IconChevron from '~/components/atoms/IconChevron';

export default function FooterNav({ className, nav, ulClassName = '', liClassName = '' }) {
  const [active, setActive] = useState('');

  return (
    <div className={`md:mb-10 md:pt-0 relative ${className}`}>
      <div className="block md:hidden absolute top-0 -left-8 -right-16 border-t border-white border-opacity-30 h-px"></div>
      <h6
        className="text-h6 py-8 md:py-0 md:mb-6 flex"
        onClick={(e) => {
          setActive(active === nav.title ? '' : nav.title);
        }}
      >
        {nav?.label}{' '}
        <div className="ml-auto">
          <IconChevron className="w-4 h-auto block md:hidden" />
        </div>
      </h6>
      <nav className={`${active === nav.title ? 'block' : 'hidden'} pb-8 md:pb-0 md:block`}>
        <ul className={ulClassName}>
          {nav?.childItems?.nodes?.map((item, i) => {
            return (
              <li key={item.url + i} className={`mb-0.5 ${liClassName}`}>
                {item.url.slice(0, 4) === 'http' ? (
                  <a
                    href={item.url}
                    className="opacity-80 transition-opacity hover:opacity-100 duration-200"
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link to={item.url} className="opacity-80 transition-opacity hover:opacity-100 duration-200">
                    {item.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
