import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Button from '~/components/atoms/Button'
import HelpFeatures from '~/components/molecules/HelpFeatures'

export default function RentalCta({className, subheadingClassName, subheading, heading, showHelpFeatures = false}) {
  const [value, setValue] = useState('');

  return (
    <div className={`${className}`}>
      <h6 className={`text-h6 mb-4 ${subheadingClassName}`}>{subheading}</h6>
      <h4 className="text-h2 mb-6">{heading}</h4>
      <form
        className="flex flex-col sm:flex-row"
        onSubmit={e => {
          e.preventDefault();
          navigate(`/pricing/?postcode=${encodeURIComponent(value)}`);
        }}
      >
        <input value={value} onChange={e => setValue(e.target.value)} type="text" placeholder="Postcode" className="bg-white px-5 py-4 md:py-6 w-full outline-none text-navy postcode-input" />
        <Button type="button" title="Get Rental Estimate" className="inline-flex items-center whitespace-nowrap" />
      </form>
      {showHelpFeatures && (
        <HelpFeatures />
      )}
    </div>
  )
}
