import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

export default function SubNav({ headerNav, active, setActive }) {
  const [items, setItems] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setItems(headerNav.filter((item) => item.label === active).pop());

    headerNav.map((nav, i) => nav.label === active && setIndex(i));
  }, [active]);

  return (
    <motion.nav
      className="absolute bottom-0 z-10 flex flex-col justify-between h-screen px-20 pb-24 pl-10 mt-0 -top-8 -left-10 -right-10 bg-sand text-navy drop-shadow"
      initial={{ x: '-100%' }}
      animate={{ x: '0' }}
      exit={{ x: '-100%' }}
      transition={{ type: 'tween', duration: 0.3 }}
      onMouseLeave={(e) => {
        setActive('');
      }}
    >
      <ul style={{ marginTop: '12vh' }}>
        <h6 class="uppercase text-blue font-medium mb-6 text-sm">{active}</h6>
        <AnimatePresence exitBeforeEnter={true}>
          {items?.childItems?.nodes?.map((item, i) => (
            <motion.li
              key={item.url + i}
              className="border-t border-b border-transparent hover:bg-black hover:bg-opacity-5 hover:border-opacity-10 hover:border-black"
              initial={{ y: '6px', opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { duration: 0.2, delay: i / 15 } }}
              exit={{ y: 0, opacity: 0, transition: { duration: 0.2, delay: i / 15 } }}
            >
              <Link
                to={item.url}
                className="block py-4 text-2xl font-medium tracking-tight transition-opacity duration-200 ease-in-out font-heading"
              >
                {item.label}
              </Link>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
      <div className="w-11/12 mx-auto mt-6">
        <AnimatePresence exitBeforeEnter={true}>
          {index === 0 ? (
            <motion.div
              key={`nav-image-1`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <StaticImage
                loading={'eager'}
                src={'../../assets/images/resident-nav.png'}
                objectFit="contain"
                objectPosition="center"
                alt=""
              />
            </motion.div>
          ) : (
            <motion.div
              key={`nav-image-2`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <StaticImage
                loading={'eager'}
                src={'../../assets/images/owner-nav.png'}
                objectFit="contain"
                objectPosition="center"
                alt=""
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.nav>
  );
}
