import React, { useState } from 'react'
import Button from '~/components/atoms/Button'
import IconPin from '~/components/atoms/IconPin';

export default function FindCta({className, subheadingClassName, subheading, heading}) {
  const [location, setLocation] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    if (location) {
      return window.location = `https://stay.cityrelay.com/flat-listing?neighborhoods=${location}`
    }
  }
  return (
    <div className={`${className}`}>
      {subheading && <h6 className={`text-h6 mb-4 ${subheadingClassName}`}>{subheading}</h6>}
      {heading && <h4 className="text-h2 mb-6">{heading}</h4>}

      <div  className="flex flex-col sm:flex-row mb-8">
        <Button url="https://stay.cityrelay.com/flat-listing" title="Find my home" className="inline-flex items-center whitespace-nowrap" />
      </div>
      {/* <div className="hidden md:flex items-center whitespace-nowrap">
        <div className="mr-4">Popular neighborhoods:</div>
        <ul className="flex">
          {[
            { title: 'Fulham' },
            { title: 'Notting Hill' },
            { title: 'Maida Vale' },
            { title: 'Chelsea' },
          ].map((item, i) => (
            <a target="_blank" rel="noopener noreferrer" href={`https://stay.cityrelay.com/flat-listing?neighborhoods=${item.title}`} key={`borough${i}`} className="flex items-center font-medium mr-4 last:mr-0 hover:opacity-50 transition-opacity duration-150"><IconPin className="w-4 mr-2" />{item.title}</a>
          ))}
        </ul>
      </div> */}
    </div>
  )
}
