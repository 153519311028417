import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
// import Scroll from "~/utils/scroll"
import Footer from '~/components/organisms/Footer';
import Header from '~/components/organisms/Header';

const Layout = ({ children, location, className = '', backgroundColour = 'sand-light', headerWhite = false, headerBgWhite = false, headerSticky = false, showResourcesNav = false, showLogin = false, footerCtaType}) => {
  const seo = useSiteInfoAssets();

  let backgroundColourClass = 'bg-sand-light'
  if (backgroundColour === 'navy') {
    backgroundColourClass = 'bg-navy text-white'
  }

  return (
    <SEOContext.Provider value={{ global: seo }}>
      {/* <Scroll callbacks={location} /> */}
      <div className={`${backgroundColourClass } ${className}`}>
        <Header headerWhite={headerWhite} headerSticky={headerSticky} headerBgWhite={headerBgWhite} showResourcesNav={showResourcesNav} showLogin={showLogin} />
        <main>{children}</main>
        <Footer footerCtaType={footerCtaType}  />
      </div>
      <div className="h-full h-1/2 h-1/3 h-2/3 h-1/4 h-3/4 h-5/6 bg-sand bg-navy text-left text-center text-h1 text-h2 text-h3 text-h4 text-h5 text-h6 bg-rose col-span-3 col-span-4" style={{display: 'none'}}></div>
    </SEOContext.Provider>
  );
};

export default Layout;
