import React from 'react'
import FindCta from '~/components/molecules/FindCta';
import RentalCta from '~/components/molecules/RentalCta';
import { StaticImage } from 'gatsby-plugin-image';

export default function FooterRentalCta({showHelpFeatures}) {
  return (
    <div className="lg:flex">
      <div className="mb-10 md:pr-4">
        <RentalCta subheading="Get an estimate" heading="See what your property can achieve with City Relay" subheadingClassName="text-blue" showHelpFeatures={showHelpFeatures} />
      </div>
      <div className="-ml-6 -mr-6 lg:ml-auto mb-10 lg:mb-12 lg:-mr-40">
        <StaticImage src={'../../assets/images/footer-rental-cta.png'} className="w-full lg:w-[42rem]" alt="" />
      </div>
    </div>
  )
}
