import React from 'react'
import IconArrow from '~/components/atoms/IconArrow';

export default function Arrow({color = 'black', className}) {
  return (
    <div className={`w-16 h-16 relative rounded-full flex justify-center hover:bg-navy-dark hover:border-navy-dark transition-colors hover:text-white items-center border transform ${color === 'white' ? 'border-white border-opacity-30 hover:bg-white hover:border-white hover:text-navy-dark' : 'border-black border-opacity-10'} ${className}`}>
      <IconArrow color={color}/>
    </div>
  )
}
